/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { Product } from './product';


export interface ProductCategory { 
    category: Category;
    productId: string;
    categoryId: string;
    createdOn: Date;
    createdBy?: string;
    product: Product;
}

