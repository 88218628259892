export const dev = {
    production: false,
    AUTH_API_URL: 'https://dev-api-auth-seech.azurewebsites.net/api/',
    AUTH_WEB_URL: 'https://dev-auth.seech.com',
    PAYMENT_WEB_URL: 'https://dev-payment.seech.com',
    ACCOUNT_WEB_URL: 'https://dev-account.seech.com',
    BASE_API_URL: 'https://dev-api-spectrum.azurewebsites.net',
    CLIENT_ID: '24A75F18-A62C-4F31-983D-60D156ADB510',
    GOOGLE_API_KEY: 'AIzaSyCR9vRtCUFO7Wbne2xG8-UMNxcLlxglNjE',
    GOOGLE_ANALYTICS_ID: '',
    SHARED_CDN_URL: 'https://dev-cdn.seech.app/shared',
    GLOBAL_CDN_URL: 'https://dev-cdn.seech.com/seech'
};