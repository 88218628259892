/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductAccessResponse { 
    productId: string;
    productName: string;
    productKey: string;
    accessState: string;
    lastAccessedOn?: Date;
    expiresOn?: Date;
    purchasedOn?: Date;
}

