/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTag } from './productTag';


export interface Tag { 
    id: string;
    tagName: string;
    productTags: Array<ProductTag>;
}

