/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScreenTimeResponse } from './screenTimeResponse';


export interface ScreenTimeGroupedByDay { 
    dayOfWeek: number;
    totalMinutes: number;
    windows: Array<ScreenTimeResponse>;
}

