import { Route } from '@angular/router';
import { AuthenticateComponent, BaseLoginComponent, LogoutComponent } from '@seech/auth-ng';
import { AccountClientService, CONSTANT } from '@spectrum/shared-ng';
import { AppResolver } from './resolvers/app.resolver';
import { SignupComponent } from './auth/components/signup.component';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./modules/features/home/home.module').then(m => m.HomeModule)
  },

  {
    path: '',
    loadChildren: () => import('./modules/features/product/product.module').then(m => m.ProductModule)
  },

  {
    path: 'subscription',
    loadChildren: () => import('./modules/features/subscription/subscription.module').then(m => m.SubscriptionModule)
  },

  // --------------------------------- PRODUCTS --------------------------------------
  {
    path: `${CONSTANT.ROUTE.PRODUCTS.LEGAL_EASE.HOME}`,
    loadChildren: () => import('./modules/products/legal-ease/legal-ease.module').then(m => m.LegalEaseModule)
  },

  {
    path: `${CONSTANT.ROUTE.PRODUCTS.CHILD_GUARD.HOME}`,
    loadChildren: () => import('./modules/products/child-guard/child-guard.module').then(m => m.ChildGuardModule)
  },

  // otherwise redirect to home
  { path: '**', redirectTo: CONSTANT.ROUTE.HOME, pathMatch: 'full' }, // route not found
];

export const authRoutes: Route[] = [
  {
    path: 'authenticate',
    component: AuthenticateComponent,
  },

  {
    path: 'signup',
    component: SignupComponent,
  },

  {
    path: 'login',
    // component: LoginComponent,
    component: BaseLoginComponent<AccountClientService>,
  },

  {
    path: 'logout',
    component: LogoutComponent,
  },

  // {
  //   path: '',
  //   redirectTo: '/login', // redirect happens in the AppResolver
  //   resolve: { action: AppResolver.resolve },
  //   pathMatch: 'full',
  // }, 
];
