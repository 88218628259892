/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GoodThing } from './goodThing';
import { Concern } from './concern';
import { NegotiationOpportunity } from './negotiationOpportunity';


export interface AnalysisResult { 
    concerns: Array<Concern>;
    goodThings: Array<GoodThing>;
    negotiationOpportunities: Array<NegotiationOpportunity>;
    summary: string;
    score: number;
}

