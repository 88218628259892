import { ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'seech apps';
  isLoading = false;

  constructor(
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {
    this.loaderService.isLoading.subscribe({
      next: (isLoading) => {
        setTimeout(() => {
          this.isLoading = isLoading;
          this.cdRef.markForCheck();
        }, 0);
      },
    });
  }

}
