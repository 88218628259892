/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductDevice } from './productDevice';
import { ProductRegion } from './productRegion';
import { ProductTag } from './productTag';
import { ProductOperatingSystem } from './productOperatingSystem';
import { ProductAccessibilityFeature } from './productAccessibilityFeature';
import { ProductAudience } from './productAudience';
import { Review } from './review';
import { PlatformInterest } from './platformInterest';
import { Feature } from './feature';
import { ProductPlatform } from './productPlatform';
import { Suggestion } from './suggestion';
import { Like } from './like';
import { Medium } from './medium';
import { ProductBadge } from './productBadge';
import { Waitlist } from './waitlist';
import { ProductCategory } from './productCategory';
import { ProductIntegrationType } from './productIntegrationType';
import { ProductUseCase } from './productUseCase';
import { ChangeLog } from './changeLog';
import { SupportedPlatform } from './supportedPlatform';
import { Download } from './download';
import { AccessRequirement } from './accessRequirement';


export interface Product { 
    id: string;
    referenceID: string;
    name: string;
    productKey: string;
    color?: string;
    logo?: string;
    hint: string;
    description: string;
    rating?: number;
    ratingCount?: number;
    likeCount?: number;
    isReleased?: boolean;
    developmentStageId?: string;
    regionId?: string;
    createdOn: Date;
    createdBy?: string;
    accessRequirements: Array<AccessRequirement>;
    changeLogs: Array<ChangeLog>;
    downloads: Array<Download>;
    features: Array<Feature>;
    likes: Array<Like>;
    media: Array<Medium>;
    platformInterests: Array<PlatformInterest>;
    productAccessibilityFeatures: Array<ProductAccessibilityFeature>;
    productAudiences: Array<ProductAudience>;
    productBadges: Array<ProductBadge>;
    productCategories: Array<ProductCategory>;
    productDevices: Array<ProductDevice>;
    productIntegrationTypes: Array<ProductIntegrationType>;
    productOperatingSystems: Array<ProductOperatingSystem>;
    productPlatforms: Array<ProductPlatform>;
    productRegions: Array<ProductRegion>;
    productTags: Array<ProductTag>;
    productUseCases: Array<ProductUseCase>;
    reviews: Array<Review>;
    suggestions: Array<Suggestion>;
    supportedPlatforms: Array<SupportedPlatform>;
    waitlists: Array<Waitlist>;
}

