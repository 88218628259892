export const ROUTE = {
    HOME: '',
    PRODUCT: 'product',
    SUBSCRIPTION: 'subscription',
    PRODUCTS: {
        LEGAL_EASE: {
            HOME: 'legal-ease',
            RESULT: 'legal-ease/analysis-result',
            BOOKMARKS: 'legal-ease/bookmarks',
        },
        CHILD_GUARD: {
            HOME: 'child-guard',
        },
    },
    // DETAIL: 'details',
    PRODUCT_PREVIEW: 'preview',
}