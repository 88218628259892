/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityTag } from './entityTag';


export interface Tag2 { 
    show: boolean;
    id: string;
    name: string;
    createdOn: Date;
    colorCode?: string;
    isVerified?: boolean;
    parentId?: string;
    description?: string;
    entity_Tags: Array<EntityTag>;
    inverseParent: Array<Tag2>;
    parent?: Tag2;
}

