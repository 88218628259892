/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScreenTimeResponse { 
    id: string;
    startTime: Date;
    endTime: Date;
    isDeactivated: boolean;
}

