/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IssueAdvice2 } from './issueAdvice2';
import { NegotiationOpportunity2 } from './negotiationOpportunity2';
import { LegalAnalysisReport } from './legalAnalysisReport';
import { GoodThing2 } from './goodThing2';


export interface AnalysisResult2 { 
    id: string;
    summary?: string;
    score: number;
    legalAnalysisReportId: string;
    createdBy: string;
    createdOn: Date;
    goodThings: Array<GoodThing2>;
    issueAdvices: Array<IssueAdvice2>;
    legalAnalysisReport: LegalAnalysisReport;
    negotiationOpportunities: Array<NegotiationOpportunity2>;
}

