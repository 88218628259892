/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';


export interface DeviceOperatingSystem { 
    id: string;
    name: string;
    description?: string;
    platformId: string;
    createdOn: Date;
    createdBy?: string;
    platform: Platform;
}

