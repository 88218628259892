/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnalysisResult2 } from './analysisResult2';


export interface LegalAnalysisReport { 
    id: string;
    isLegalDocument: boolean;
    analysisResultId?: string;
    errorMessage?: string;
    createdBy: string;
    createdOn: Date;
    analysisResults: Array<AnalysisResult2>;
}

