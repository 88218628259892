/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AudienceResponse } from '../model/models';
import { BadgesResponse } from '../model/models';
import { CategoryResponse } from '../model/models';
import { DevelopmentStagesResponse } from '../model/models';
import { DeviceOperatingSystemResponse } from '../model/models';
import { DeviceResponse } from '../model/models';
import { IntegralTypeResponse } from '../model/models';
import { PlatformResponse } from '../model/models';
import { RegionResponse } from '../model/models';
import { TagResponse } from '../model/models';
import { UseCaseResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LookupServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    lookupGetAudience(extraHttpRequestParams?: any): Observable<Array<AudienceResponse>>;

    /**
     * 
     * 
     */
    lookupGetBadges(extraHttpRequestParams?: any): Observable<Array<BadgesResponse>>;

    /**
     * 
     * 
     */
    lookupGetCategories(extraHttpRequestParams?: any): Observable<Array<CategoryResponse>>;

    /**
     * 
     * 
     */
    lookupGetDevelopmentStages(extraHttpRequestParams?: any): Observable<Array<DevelopmentStagesResponse>>;

    /**
     * 
     * 
     */
    lookupGetDeviceOperatingSystems(extraHttpRequestParams?: any): Observable<Array<DeviceOperatingSystemResponse>>;

    /**
     * 
     * 
     */
    lookupGetDevices(extraHttpRequestParams?: any): Observable<Array<DeviceResponse>>;

    /**
     * 
     * 
     */
    lookupGetIntegrationTypes(extraHttpRequestParams?: any): Observable<Array<IntegralTypeResponse>>;

    /**
     * 
     * 
     */
    lookupGetPlatforms(extraHttpRequestParams?: any): Observable<Array<PlatformResponse>>;

    /**
     * 
     * 
     */
    lookupGetRegions(extraHttpRequestParams?: any): Observable<Array<RegionResponse>>;

    /**
     * 
     * 
     */
    lookupGetTags(extraHttpRequestParams?: any): Observable<Array<TagResponse>>;

    /**
     * 
     * 
     */
    lookupGetUseCases(extraHttpRequestParams?: any): Observable<Array<UseCaseResponse>>;

}
