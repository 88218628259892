/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { Product } from './product';


export interface SupportedPlatform { 
    platform: Platform;
    productId: string;
    platformId: string;
    downloadPageUrl: string;
    directDownloadUrl: string;
    version?: string;
    releaseDate?: Date;
    isReleasedOnPlatform?: boolean;
    product: Product;
}

