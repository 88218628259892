/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Product } from './product';


export interface ProductAccess { 
    product: Product;
    id: string;
    productId: string;
    userId: string;
    accessState: string;
    lastAccessedOn?: Date;
    expiresOn?: Date;
    purchasedOn?: Date;
    user: User;
}

