/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DomainRequest { 
    domainName: string;
    category: string;
    friendlyReason: string;
    trackingReasonIds: Array<string>;
}

