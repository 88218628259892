/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { Product } from './product';


export interface Review { 
    createdByUser: User;
    id: string;
    productId: string;
    title?: string;
    rating?: number;
    comment?: string;
    isPublishable?: boolean;
    createdBy: string;
    createdOn: Date;
    product: Product;
}

