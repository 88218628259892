/**
 * Spectrum API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AnalysisResult2 } from '../model/models';
import { LegalAnalysisReport } from '../model/models';
import { LegalContentRequest } from '../model/models';
import { LegalReportAnalysis } from '../model/models';
import { LegalUrlRequest } from '../model/models';
import { ResponseObject } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LegalEaseServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param legalContent 
     */
    legalEaseAnalyze(legalContent: LegalContentRequest, extraHttpRequestParams?: any): Observable<LegalReportAnalysis>;

    /**
     * 
     * 
     * @param file 
     */
    legalEaseAnalyzeFile(file?: Blob, extraHttpRequestParams?: any): Observable<LegalReportAnalysis>;

    /**
     * 
     * 
     * @param request 
     */
    legalEaseAnalyzeUrl(request: LegalUrlRequest, extraHttpRequestParams?: any): Observable<LegalReportAnalysis>;

    /**
     * 
     * 
     * @param analysisResultId 
     */
    legalEaseDeleteAnalysisReport(analysisResultId: string, extraHttpRequestParams?: any): Observable<AnalysisResult2>;

    /**
     * 
     * 
     * @param analysisResultId 
     */
    legalEaseRetrieveAnalysisReportDetail(analysisResultId: string, extraHttpRequestParams?: any): Observable<LegalReportAnalysis>;

    /**
     * 
     * 
     */
    legalEaseRetrieveAnalysisReports(extraHttpRequestParams?: any): Observable<Array<LegalAnalysisReport>>;

    /**
     * 
     * 
     * @param request 
     */
    legalEaseSave(request: LegalReportAnalysis, extraHttpRequestParams?: any): Observable<ResponseObject>;

}
